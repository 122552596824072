*::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}
@font-face {
	font-family: SVN-Product Sans;
	src: url(./fonts/Product_Sans_Regular.ttf);
}
@font-face {
	font-family: SVN-Product Sans;
	src: url(./fonts/Product_Sans_Bold.ttf);
	font-weight: bold;
}
@font-face {
	font-family: SVN-Product Sans;
	src: url(./fonts/Product_Sans_Italic.ttf);
	font-style: italic;
}
@font-face {
	font-family: SVN-Product Sans;
	src: url(./fonts/Product_Sans_Bold_Italic.ttf);
	font-weight: bold;
	font-style: italic;
}

body {
	font-family: SVN-Product Sans !important;
	color: #232026 !important;
}
* {
	scroll-behavior: smooth;
}
.ReactModal__Overlay {
	opacity: 0;
	transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
	opacity: 1;
}

.ReactModal__Overlay--before-close {
	opacity: 0;
}

/* Global val */

html {
	font-size: 22px;
}

@media screen and (max-width: 1580px) {
	html {
		font-size: 18px;
	}
}

@media screen and (max-width: 1310px) {
	html {
		font-size: 16px;
	}
}
@media screen and (max-width: 996px) {
	html {
		font-size: 14px;
	}
}
